<template>
  <div>
    <v-form v-model="form" ref="form">
      <auto-completar
        v-model="vehiculo"
        :items="vehicles"
        item-text="datos.tituloPrecio"
        item-value="datos.patente"
        clearable
        is-outline
        color="info"
        return-object
        :outlined="!!vehiculo"
        hide-selected
        required
        :rules="[(v) => !!v || 'Campo Requerido']"
        placeholder="Busque su vehículo"
      ></auto-completar>
      <text-dinamico
        v-model="nombre"
        :required="true"
        placeholder="Nombre"
        :new-rules="[
          (v) =>
            (v && v.length >= 2) ||
            'El Nombre debe tener al menos 2 caracteres',
        ]"
        is-outline
      />
      <text-dinamico
        v-model="apellido"
        :required="true"
        placeholder="Apellidos"
        :new-rules="[
          (v) =>
            (v && v.length >= 2) ||
            'El Apellido debe tener al menos 2 caracteres',
        ]"
        is-outline
      />
      <text-dinamico
        v-model="telefono"
        :required="true"
        placeholder="Teléfono"
        is-outline
        :new-rules="[
          (v) =>
            (v && v.length >= 12) ||
            'El Teléfono debe tener al menos 12 caracteres',
          (v) =>
            /^(?:\+?56)?(?:\s?)(?:9\d{8}|2\d{7}|[3-4]\d{7}|[5-7]{1}[2-9]{1}\d{6})$/.test(
              v
            ) || 'Telefono Invalido',
        ]"
      />
      <auto-completar
        v-if="Boolean(telefono && telefono.length === 12)"
        v-model="contact"
        placeholder="Contacto de preferencia"
        :disabled="!contacts.length"
        is-outline
        required
        :items="contacts"
      />
    </v-form>
    <v-col cols="12">
      <v-row
        align="center"
        :justify="$vuetify.breakpoint.smAndDown ? 'center' : 'end'"
        no-gutters
      >
        <v-btn color="info" :loading="formSend" @click="cotizar">
          Enviar
        </v-btn>
      </v-row>
    </v-col>
    <v-snackbar :timeout="5000" v-model="snackbar" color="info">
      <v-row justify="center" align="center" no-gutter>
        <v-icon left color="white">{{ iconMensaje }}</v-icon>
        {{ snackbarMensaje }}
      </v-row>
    </v-snackbar>
    <v-dialog v-model="openGif" persistent width="220">
      <v-img :src="gif" />
    </v-dialog>
    <v-dialog v-model="dialog" width="410">
      <v-card>
        <v-card-title class="text-h5 info--text">
          <v-row
            align="center"
            justify="center"
            no-gutters
            class="font-weight-bold"
          >
            Estamos gestionando tu solicitud
          </v-row>
        </v-card-title>
        <v-card-text class="mt-3 text-subtitle-1 text-justify">
          Un ejecutivo te contactará cuanto antes, pero dentro de horario
          laboral<br /><br />

          Horario de atención presencial:<br /><br />

          Lunes a viernes: 10 a 19 hrs<br />
          Sábados: 10 a 15 hrs<br /><br />

          Gracias por tu comprensión
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import TextDinamico from '@/components/textDinamico';
import {mapActions, mapMutations, mapState} from 'vuex';
import AutoCompletar from '@/components/core/autoCompletar.vue';

export default {
  name: 'formularioContacto',
  components: {AutoCompletar, TextDinamico},
  data: () => ({
    openGif: false,
    gif: require('@/assets/petersen ppu.gif'),
    formSend: false,
    purchase: null,
    dialog: false,
    snackbar: false,
    iconMensaje: '',
    message: '',
    snackbarMensaje: '',
    nombre: null,
    apellido: null,
    form: false,
    telefono: '+569',
    vehiculo: '',
    contact: null,
    contacts: ['💬 Whatsapp', '📱 Teléfono'],
  }),
  computed: {
    ...mapState(['correoSend', 'stockGeneral']),
    vehicles() {
      if (!this.stockGeneral || !this.stockGeneral.length) return [];
      return this.stockGeneral.filter((auto) => {
        const arr = auto.datos.status.split(' ');

        arr.shift();

        const status = arr.join(' ');

        return status !== 'RESERVADO' && status !== 'VENTA EN PROCESO';
      });
    },
  },
  watch: {
    dialog(val) {
      if (!val) {
        this.SET_CORREOSEND(val);
        this.$router.push({name: 'petersen'}).catch(() => {});
      }
    },
    correoSend(val) {
      if (val) {
        this.formSend = false;
        this.dialog = val;
      }
    },
    vehicles: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val.length) {
          const {
            $route: {
              query: {vehicle},
            },
          } = this;

          const search = val.find((auto) => auto.id === vehicle);

          if (search) {
            this.vehiculo = search.datos.patente;
          }
        }
      },
    },
  },
  methods: {
    ...mapActions(['SEND_CONTACT', 'NOTIFY_ERROR', 'SEND_BUY_GENIO']),
    ...mapMutations(['SET_CORREOSEND']),
    cotizar() {
      if (!this.form) {
        this.snackbar = true;
        this.iconMensaje = 'warning';
        this.snackbarMensaje = 'Debe Llenar todos los campos requeridos!!!';
        this.$refs.form.validate();
        return;
      }

      const cotizar = {
        nombre: this.nombre,
        apellido: this.apellido,
        telefono: this.telefono,
        url: 'automotrizpetersen.cl' + this.$route.path,
        tipo: 'contacto',
        contact: this.contact,
        vehiculo: this.vehiculo.id,
        type: 'contacto',
      };
      this.purchase = cotizar;
      this.formSend = true;
      this.SEND_CONTACT(cotizar);
      //this.SEND_BUY_GENIO(cotizar);
      this.$refs.form.reset();
    },
    whatsapp() {
      const link = `https://api.whatsapp.com/send?phone=+56944436444&text=¡Hola!%20Mi%20nombre%20es%20${this.purchase.nombre}%20${this.purchase.apellido},%20acabo%20de%20enviar%20una%20solicitud%20de%20contacto,%20de%20la%20página%20web.%20¿Podrían%20confirmar%20si%20la%20recibieron%20y%20cuándo%20podré%20recibir%20una%20respuesta?%20Muchas%20gracias.`;
      window.open(link, '_blank');
    },
  },
};
</script>

<style scoped></style>
