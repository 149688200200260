var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-form',{ref:"form",model:{value:(_vm.form),callback:function ($$v) {_vm.form=$$v},expression:"form"}},[_c('auto-completar',{attrs:{"items":_vm.vehicles,"item-text":"datos.tituloPrecio","item-value":"datos.patente","clearable":"","is-outline":"","color":"info","return-object":"","outlined":!!_vm.vehiculo,"hide-selected":"","required":"","rules":[function (v) { return !!v || 'Campo Requerido'; }],"placeholder":"Busque su vehículo"},model:{value:(_vm.vehiculo),callback:function ($$v) {_vm.vehiculo=$$v},expression:"vehiculo"}}),_c('text-dinamico',{attrs:{"required":true,"placeholder":"Nombre","new-rules":[
        function (v) { return (v && v.length >= 2) ||
          'El Nombre debe tener al menos 2 caracteres'; } ],"is-outline":""},model:{value:(_vm.nombre),callback:function ($$v) {_vm.nombre=$$v},expression:"nombre"}}),_c('text-dinamico',{attrs:{"required":true,"placeholder":"Apellidos","new-rules":[
        function (v) { return (v && v.length >= 2) ||
          'El Apellido debe tener al menos 2 caracteres'; } ],"is-outline":""},model:{value:(_vm.apellido),callback:function ($$v) {_vm.apellido=$$v},expression:"apellido"}}),_c('text-dinamico',{attrs:{"required":true,"placeholder":"Teléfono","is-outline":"","new-rules":[
        function (v) { return (v && v.length >= 12) ||
          'El Teléfono debe tener al menos 12 caracteres'; },
        function (v) { return /^(?:\+?56)?(?:\s?)(?:9\d{8}|2\d{7}|[3-4]\d{7}|[5-7]{1}[2-9]{1}\d{6})$/.test(
            v
          ) || 'Telefono Invalido'; } ]},model:{value:(_vm.telefono),callback:function ($$v) {_vm.telefono=$$v},expression:"telefono"}}),(Boolean(_vm.telefono && _vm.telefono.length === 12))?_c('auto-completar',{attrs:{"placeholder":"Contacto de preferencia","disabled":!_vm.contacts.length,"is-outline":"","required":"","items":_vm.contacts},model:{value:(_vm.contact),callback:function ($$v) {_vm.contact=$$v},expression:"contact"}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"align":"center","justify":_vm.$vuetify.breakpoint.smAndDown ? 'center' : 'end',"no-gutters":""}},[_c('v-btn',{attrs:{"color":"info","loading":_vm.formSend},on:{"click":_vm.cotizar}},[_vm._v(" Enviar ")])],1)],1),_c('v-snackbar',{attrs:{"timeout":5000,"color":"info"},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_c('v-row',{attrs:{"justify":"center","align":"center","no-gutter":""}},[_c('v-icon',{attrs:{"left":"","color":"white"}},[_vm._v(_vm._s(_vm.iconMensaje))]),_vm._v(" "+_vm._s(_vm.snackbarMensaje)+" ")],1)],1),_c('v-dialog',{attrs:{"persistent":"","width":"220"},model:{value:(_vm.openGif),callback:function ($$v) {_vm.openGif=$$v},expression:"openGif"}},[_c('v-img',{attrs:{"src":_vm.gif}})],1),_c('v-dialog',{attrs:{"width":"410"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 info--text"},[_c('v-row',{staticClass:"font-weight-bold",attrs:{"align":"center","justify":"center","no-gutters":""}},[_vm._v(" Estamos gestionando tu solicitud ")])],1),_c('v-card-text',{staticClass:"mt-3 text-subtitle-1 text-justify"},[_vm._v(" Un ejecutivo te contactará cuanto antes, pero dentro de horario laboral"),_c('br'),_c('br'),_vm._v(" Horario de atención presencial:"),_c('br'),_c('br'),_vm._v(" Lunes a viernes: 10 a 19 hrs"),_c('br'),_vm._v(" Sábados: 10 a 15 hrs"),_c('br'),_c('br'),_vm._v(" Gracias por tu comprensión ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }